import React from 'react';
import firebase from "gatsby-plugin-firebase";
import { Layout } from '@common';
import About from '@sections/About';
import Events from '@sections/Events';
import Highlights from '@sections/Highlights';
import Partner from '@sections/Partner';

const IndexPage = () => {
  React.useEffect(() => {
    firebase
    .analytics()
    .logEvent('visited_beranda');
  }, []);
  
  return (
  <Layout page="Beranda">
    <About />
    <Events />
    <Highlights />
    <Partner />
  </Layout>
)};

export default IndexPage;
