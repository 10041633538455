const CONTENT = [
  {
    title: 'BERADA DI',
    desc: '12 universitas',
    subdesc: 'in Indonesia'
  },
  {
    title: 'MENJANGKAU',
    desc: '50.000+',
    subdesc: 'masyarakat'
  },
  {
    title: 'MELIBATKAN',
    desc: '300+ mahasiswa',
    subdesc: 'dari berbagai universitas'
  },
];
  
  export default CONTENT;
  