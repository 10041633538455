import styled from 'styled-components';

export const LogoGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 12px;
  margin-top: 36px;
  width: 100%;

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr 1fr;
  }
`;