import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { Section, Container, Button } from '@components/global';
import { Event } from '@common';
import{ handleDate } from '@utils';

const Events = () => {
  const data = useStaticQuery(graphql`
    query {
      events: allEvent(sort: {order: DESC, fields: date}, limit: 2) {
        edges {
          node {
            date(formatString: "dddd, DD MMMM YYYY", locale: "id")
            timestamp: date(formatString: "x")
            desc
            pembicara
            title
            type
            by
            hiddenTag: hidden_tag
          }
        }
      }
    }
  `);

  return (
    <Section id="event" accent>
      <Container>
        <h2>Event Terbaru</h2>
        {data.events.edges.map(({ node }, idx) => (
          <Event
            key={idx}
            title={node.title}
            date={handleDate(node.date, node.timestamp, node.hiddenTag)}
            desc={node.desc}
            tags={node.by}
            inverse={idx%2}
          />
        ))}
        <div style={{ textAlign: 'center' }}>
          <Link to={'/events'}>
            <Button big>Lihat Semua Event</Button>
          </Link>
        </div>
      </Container>
    </Section>
  )
};

export default Events;
