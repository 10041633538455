import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Section, Container, TitleLink } from '@components/global';
import { LogoGrid } from './style';
import Img from 'gatsby-image';

const Partner = () => (
  <StaticQuery
    query={graphql`
      query {
        logos: allFile(
          sort: { order: ASC, fields: childImageSharp___resolutions___height }
          filter: { sourceInstanceName: { eq: "logos" } }
        ) {
          edges {
            node {
              name
              childImageSharp {
                fluid(maxWidth: 500) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="kolaborasi">
        <Container>
          <h2>
            <TitleLink to="/events" style={{ textDecoration: 'none' }}>
              Sudah Berkolaborasi Dengan
            </TitleLink>
          </h2>
          <div style={{ marginTop: '56px' }}>
            <LogoGrid>
              {data.logos.edges.map(({ node }) => (
                <div key={node.name}>
                  <Img
                    fluid={node.childImageSharp.fluid}
                    alt={node.name}
                    style={{ margin: '0.25rem', maxHeight: '8.5vh' }}
                    imgStyle={{ objectFit: 'contain' }}
                  />
                </div>
              ))}
            </LogoGrid>
          </div>
        </Container>
      </Section>
    )}
  />
);

export default Partner;
