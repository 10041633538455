import React from 'react';
import { Section, Container } from '@components/global';
import { Highlight } from '@common';
import CONTENT from './content';

const Highlights = () => (
  <Section id="highlights" accent="secondary">
    <Container flex>
      {CONTENT.map((data, idx) => (
        <Highlight
          key={idx}
          title={data.title}
          desc={data.desc}
          subdesc={data.subdesc}
        />
      ))}
    </Container>
  </Section>
);

export default Highlights;
