import React from 'react';
import { useLocation } from '@reach/router';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { Container, Section } from '@components/global';
import { Card, FormSelect } from '@common';
import { Filter } from './style';
import { handleDate } from '@utils';

const AllEvents = () => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const byParams = searchParams.get('by');
  const typeParams = searchParams.get('type');
  const data = useStaticQuery(graphql`
    query {
      events: allEvent(sort: { order: DESC, fields: date }) {
        edges {
          node {
            date(formatString: "DD MMMM YYYY", locale: "id")
            timestamp: date(formatString: "x")
            desc
            pembicara
            title
            type
            by
            hiddenTag: hidden_tag
          }
        }
        allType: distinct(field: type)
        allTeams: distinct(field: by)
      }
    }
  `);

  const handleFilter = (value, name) => {
    const otherParams =
      name === 'Tim'
        ? { key: 'type', value: searchParams.get('type') }
        : { key: 'by', value: searchParams.get('by') };
    let url =
      value === 'Semua'
        ? '?'
        : name === 'Tim'
        ? `?by=${value}`
        : `?type=${value}`;
    if (otherParams.value) {
      url +=
        url === '?'
          ? `${otherParams.key}=${otherParams.value}`
          : `&${otherParams.key}=${otherParams.value}`;
    }
    navigate(url+'#allevents');
  };

  const teamList = ['Semua', ...data.events.allTeams];
  const categoryList = ['Semua', ...data.events.allType];

  const isTeamInList = (list, team) => {
    for (let i = 0; i < list.length; i++) {
      if (list[i] === team) return true;
    }
    return false;
  };

  const filterData = (team, category) => {
    if (!team) {
      return data.events.edges.filter(event => event.node.type === category);
    }
    if (!category) {
      return data.events.edges.filter(event =>
        isTeamInList(event.node.by, team)
      );
    }
    return data.events.edges.filter(
      event => event.node.type === category && isTeamInList(event.node.by, team)
    );
  };

  const eventData =
    !byParams && !typeParams
      ? data.events.edges
      : filterData(byParams, typeParams);

  return (
    <Section id="allevents">
      <Container>
        <h2>Kegiatan SRE</h2>
        <Filter>
          <div style={{ textAlign: 'center', marginRight: '1rem' }}>
            <FormSelect
              title="Kategori"
              selected={typeParams ? typeParams : 'Semua'}
              setSelected={handleFilter}
              optionList={categoryList}
            />
          </div>
          <div style={{ textAlign: 'center' }}>
            <FormSelect
              title="Tim"
              selected={byParams ? byParams : 'Semua'}
              setSelected={handleFilter}
              optionList={teamList}
            />
          </div>
        </Filter>
        {!(!typeParams && !byParams) && (
          <h3 style={{ textAlign: 'center' }}>
            {eventData.length} hasil ditemukan
          </h3>
        )}
        <div>
          <Container grid>
            {eventData.map((event, idx) => (
              <Card
                key={idx}
                title={event.node.title}
                text={event.node.desc}
                subtitle={event.node.type}
                date={handleDate(event.node.date, event.node.timestamp, event.node.hiddenTag)}
                by={event.node.by}
              />
            ))}
          </Container>
        </div>
      </Container>
    </Section>
  );
};

export default AllEvents;
