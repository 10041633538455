import React from 'react';
import { Link } from 'gatsby';
import { Section, Button } from '@components/global';
import CONTENT from './content';
import { AboutContainer, Paragraph } from './style';

const About = () => (
  <Section id="about">
    <AboutContainer>
      <h2>{CONTENT.title}</h2>
      <Paragraph>{CONTENT.text}</Paragraph>
      <Link to="/about">
        <Button>{CONTENT.button}</Button>
      </Link>
    </AboutContainer>
  </Section>
);

export default About;
