import styled from 'styled-components';
import { Container } from '@components/global';

export const AboutContainer = styled(Container)`
  width: 35%;
  text-align: center;

  @media (max-width: ${props => props.theme.screen.md}) {
    width: 60%;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 100%;
  }
`;

export const Paragraph = styled.p`
  margin: 20px;
`;